<template>
  <section class="q-pa-md">
    <Mural
      :breadcrumbs="breadcrumbs"
      class="q-mb-md"
      route="new-class"
      :query="{
        courseId: courseId,
        title: titleCourse,
        isFinished: finishedCourse,
      }"
    >
      <q-btn
        color="default-pink"
        rounded
        no-caps
        no-wrap
        :disable="trail.participants.length > 0 ? false : true"
        label="Salvar participantes"
        @click="addUsers"
      />
    </Mural>
    <div class="container-participants">
      <div>
        <div class="">
          <label class="q-mr-sm">Segmentos</label>
          <q-img
            src="@/assets/icons/info_cicle.svg"
            width="16px"
            height="16px"
          />
          <q-tooltip anchor="top left" self="top left">
            Utilize os segmentos para poder filtrar os grupos
          </q-tooltip>
        </div>
        <q-select
          dense
          outlined
          color="default-pink"
          bg-color="grey-3"
          class="col-11"
          id="semgments"
          multiple
          use-chips
          use-input
          emit-value
          map-options
          @filter="filterFnBranches"
          v-model="trail.branches"
          :options="listBranches"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                Não encontrado
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="container-groups">
        <div class="item-groups">
          <div>
            <label for="" class="q-mr-sm">Grupos</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top left" self="top left">
              Utilize os grupos para poder filtrar os usuários
            </q-tooltip>
          </div>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            multiple
            use-input
            use-chips
            :disable="trail.branches.length > 0 ? false : true"
            emit-value
            @filter="filterFnGroups"
            map-options
            v-model="trail.groups"
            :options="optionsGroups"
            @update:model-value="handleSelectionOfGroups"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <q-checkbox
          left-label
          :disable="trail.branches.length > 0 ? false : true"
          v-model="allGroups"
          label="Selecionar todos"
          color="default-pink"
          @update:model-value="selectedFilteredGroups"
        />
      </div>
      <div class="container-users">
        <div class="item-users">
          <label for="">Usuários</label>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            multiple
            use-input
            :disable="trail.groups.length > 0 ? false : true"
            use-chips
            @filter="filterFnUsers"
            map-options
            v-model="trail.participants"
            :options="optionsUsers"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <q-checkbox
          left-label
          :disable="trail.groups.length > 0 ? false : true"
          v-model="allUsers"
          label="Selecionar todos"
          color="default-pink"
          @update:model-value="selectedAllUsers"
        />
      </div>
    </div>
  </section>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";

/* vue */
import { ref, reactive, onMounted, watch } from "vue";

/* Vue router */
import { useRouter, useRoute } from "vue-router";

/* Quasar */
import { useQuasar } from "quasar";

/* Services */
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import TrailDataServices from "@/services/TrailDataServices";

export default {
  name: "AddUsersInClass",
  components: {
    Mural,
  },
  setup() {
    /* Quasar */
    const $q = new useQuasar();

    /* Vue-router */
    const _route = new useRoute();
    const _router = new useRouter();

    /* Constants */
    const courseId = _route.query.courseId;
    const titleCourse = _route.query.title;
    const breadcrumbs = ref(["Adicionar usuários"]);
    const trail = reactive({
      branches: [],
      groups: [],
      participants: [],
    });
    const listBranches = ref([]);
    const optionListBranches = ref([]);
    const allGroups = ref(false);
    const allUsers = ref(false);
    const listGroups = ref([]);
    const optionsGroups = ref([]);
    const listUsers = ref([]);
    const optionsUsers = ref([]);
    const loading = ref(true);

    /* Services */
    const _GroupServices = new GroupServices();
    const _userServices = new UserServices();
    const _trailServices = new TrailDataServices();

    /* Functions */
    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (trail.branches.length > 0) {
            optionsGroups.value = listGroups.value.filter((group) => {
              return trail.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (trail.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              trail.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function filterFnUsers(val, update) {
      if (val === "") {
        update(() => {
          if (trail.branches.length > 0) {
            optionsUsers.value = listUsers.value.filter((users) => {
              return trail.branches.includes(users?.branch_id);
            });
          } else {
            optionsUsers.value = listUsers.value;
          }
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsUsers.value = optionsUsers.value.filter((v) => {
          if (trail.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              trail.branches.includes(v?.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!trail.groups.includes(group.value)) {
            trail.groups.push(group.value);
          }
          handleSelectionOfGroups();
        });
      } else {
        while (trail.groups.length != 0) {
          trail.groups.pop();
        }
        listUsers.value = [];
        optionsUsers.value = [];
        trail.participants = [];
        allUsers.value = false;
      }
    }

    function selectedAllUsers() {
      if (allUsers.value) {
        optionsUsers.value.forEach((user) => {
          trail.participants.push(user);
        });
      } else {
        while (trail.participants.length != 0) {
          trail.participants.pop();
        }
      }
    }

    async function handleSelectionOfGroups() {
      if (trail.groups.length > 0) {
        loading.value = true;
        /*   listUsers.value = []; */
        const formData = new FormData();

        trail.groups.forEach((id) => {
          formData.append("Groups", id);
        });

        await _userServices
          .getAllUserByGroup(formData)
          .then((response) => {
            response.data.map((user) => {
              let { id, first_name, last_name, branch_id, group_id } = user;
              listUsers.value.push({
                label: `${first_name} ${last_name}`,
                value: id,
                branch_id: branch_id,
                group_id: group_id,
              });
            });

            listUsers.value = listUsers.value.filter((item, index, self) => {
              return (
                index ===
                self.findIndex((t) => {
                  return t.value === item.value;
                })
              );
            });

            optionsUsers.value = listUsers.value;
            loading.value = false;
          })

          .catch((error) => {
            loading.value = false;
          });
      } else {
        optionsUsers.value = [];
        listUsers.value = [];
        trail.participants = [];
      }
    }

    async function getAllGroups() {
      _GroupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          optionsGroups.value = listGroups.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getBranches() {
      await _GroupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function addUsers() {
      loading.value = true;
      await _trailServices
        .addStudentsInTrail(trail.participants, courseId)
        .then((response) => {
          if (response.data) {
            _router.push({
              name: "new-class",
              query: { courseId: courseId, title: titleCourse },
            });
            _alertOf(
              `Usuários adicionados ao curso “${titleCourse}”`,
              "green-9"
            );
            loading.value = false;
          }
          loading.value = false;
        })
        .catch((error) => {
          _alertOf(`Erro ao tentar salvar as alterações`, "red");
          students = [];
          loading.value = false;
        });
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    /* LifeCycles */
    onMounted(() => {
      getAllGroups();
      getBranches();
    });

    watch(
      () => trail.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          trail.groups = null;
          trail.groups = [];
          allGroups.value = false;
          optionsUsers.value = [];
          listUsers.value = [];
          trail.participants = [];
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          trail.groups = groupValues.filter((value) => {
            if (trail.groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(
      () => [trail.groups],
      ([newGroup], [oldGroup]) => {
        if (newGroup.length != 0) {
          trail.participants = trail.participants.filter((participant) => {
            return newGroup.includes(participant.group_id);
          });
        } else {
          trail.participants = [];
          allUsers.value = false;
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && trail.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!trail.groups.includes(group.value)) {
              trail.groups.push(group.value);
            }
          });
        }
      }
    });

    return {
      /* Constants */
      courseId,
      titleCourse,
      breadcrumbs,
      trail,
      listBranches,
      allGroups,
      allUsers,
      optionsGroups,
      loading,
      optionsUsers,
      /* Functions */
      filterFnUsers,
      filterFnBranches,
      selectedFilteredGroups,
      selectedAllUsers,
      filterFnGroups,
      handleSelectionOfGroups,
      addUsers,
    };
  },
};
</script>

<style scoped>
label {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
}

:is(.container-groups, .container-users) {
  display: flex;
  flex-wrap: nowrap;
}

:is(.item-groups, .item-users) {
  width: calc(80% - 25px);
  margin-right: 25px;
}

.container-participants {
  display: flex;
  flex-direction: column;
}

.container-participants > div {
  margin-bottom: 40px;
}
</style>
